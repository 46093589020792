@import "mixins";
@import "variables";

.spinnerContainer {
    position: fixed;
    top: 70%;
    left: 49%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
  
  /* Styles for the spinning wheel */
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #ffffff; /* Adjust the color as needed */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: fixed;
  }
  
  /* Animation for spinning */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .alerticon{
    height: 25px;
  }

  .modaltext {
    max-width: 670px;
    text-align: left;
    font-weight: 100;
    font-size: 16px;
    line-height: 1.7;
    color: black
  }
  