@import "../mixins";
@import "../variables";

.alerticon{
    height: 25px;
}

.errortext {
    width: 600px;
}

.errorinfo {
    color: black;
}