@import "../mixins";
@import "../variables";
@import '~react-pro-sidebar/dist/scss/variables';


.pdficon {
    float: right;
    cursor: pointer;
    height: 29px;
  }

.pwrpicon {
    float: right;
    cursor: pointer;
    height: 25px;
}

.excelicon {
    float: right;
    cursor: pointer;
    height: 29px;
}

.dividericon {
    float: right;
    height: 31px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates 2 columns of equal width */
    gap: 20px; /* Adjust the gap between items */
    justify-items: center; /* Centers items horizontally */
    align-items: center; /* Centers items vertically */
}

.exportTitle {
    text-align: center;
    color: black;
    margin-bottom: 10px;
    border-bottom: black;
    border-bottom-style: double;
}