@import "../mixins";
@import "../variables";

.loginTitle {
  @include display-flex();
  @include justify-content-center();
  font-size: 25px;
  margin-bottom: 45px;
  margin-top: 22px;
  color: black;
}

.loginLogo {
  width: 350px;
  cursor: pointer;
}