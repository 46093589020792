@import "../mixins";
@import "../variables";
@import '~react-pro-sidebar/dist/scss/variables';

.Menu {
  width: $menu-width;
}

.Item {
  background-color: gray;
}

.SubMenu {
  background-color: #f68326;
}
.SubMenu:hover {
  background-color: #f68326;
}

.ps-menu-button {
  background-color: red  !important;
  color: black  !important;
  &:hover {
    background-color: red; // Example: change the hover background color
  }
}

.tab {
    display: inline-block;
    padding: 6px 7px;
    background-color: whitesmoke;
    color: #212529;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid transparent;
    border-top-left-radius: 6px;
    border-top-right-radius: 13px;
    transition: background-color 0.3s;
    margin-top: 11px;
    font-size: 14px;
  }
  
  .tab:hover {
    background-color: #bebdbd;
  }
  
  .tab.active {
    border-bottom-color: #212529;
    border-width: medium;
    background: whitesmoke;
    margin: 5px;
  }

  .breadcrumb {
    float: left;
    margin-top: 18px;
    margin-left: 8px;
    color: #212529;
  }

  // .breadcrumb:hover {
  //   cursor: pointer;
  //   color: #bebdbd;
  // }

  .logout {
    float: right;
    margin-top: 16px;
    margin-right: 8px;
    color: white;
    font-weight: normal;
    font-size: 16px;
    font-weight: 700;
  }

  .logout:hover {
    cursor: pointer;
    color: #bebdbd;
  }

  .tabs {
    //margin-left: calc( (100vw - 1600px) / 2)
    margin-left: 20px;
  }
  .auxtabframe {
    border: 0;
    //margin-left: calc( (100vw - 1600px) / 2);
    width: 0;
    height: 0;
    background-repeat: no-repeat, repeat;
    background-position: center;
    display:none;
    background-color: #eaebf0;
  }

  .tabframe {
    border: 0;
    //margin-left: calc( (100vw - 1600px) / 2);
    //width: calc( (100vw - 40px));
    height: calc( (100vh - 45px));
    background-repeat: no-repeat, repeat;
    background-position: center;
    display:none;
    background-color: $back-color;
  }

  .icon {
    float: right;
    margin-top: 15px;
    margin-left: 5px;
    cursor: pointer;
    height: 20px;
  }

  .menuicon {
    float: left;
    margin-top: 11px;
    margin-right: 20px;
    cursor: pointer;
    height: 26px;
    //transition: margin-left 0.5s ease, width 0.5s ease; /* Add transition */
  }

  .menuiconOpen {
    float: left;
    margin-top: 11px;
    margin-right: 20px;
    margin-left: 0;
    cursor: pointer;
    height: 26px;
    //transition: margin-left 0.5s ease, width 0.5s ease; /* Add transition */

  }


  .pdficon {
    float: right;
    margin-top: 8px;
    cursor: pointer;
    height: 29px;
  }

  .titleLogo {
    float: left;
    cursor: pointer;
    height: 36px;
    margin-top: 11px;
  }

  .titleText {
    line-height: 44px;
    font-size: 22px;
  }

  .pwrpicon {
    float: right;
    margin-top: 10px;
    cursor: pointer;
    height: 25px;
    margin-right: 8px;
  }

  .downloadicon {
    float: right;
    margin-top: 9px;
    cursor: pointer;
    height: 27px;
    margin-right: 10px;
  }

  .companyicon {
    float: right;
    margin-top: 6px;
    cursor: pointer;
    height: 30px;
    margin-right: 30px;
    background-color: white;
    border-radius: 10%;
  }

  .gsicon {
    float: left;
    margin-right: 0;
    margin-left: 17px;
    margin-bottom: 10px;
    margin-top: 17px;
    cursor: pointer;
    height: 65px;
  }

  .dividericon {
    float: right;
    margin-top: 7px;
    margin-right: 12px;
    height: 31px;
  }

  .toolbar {
    width: 100%;
    fill: black;
    background-color: $sky-theme;
    display: inline-block;
    margin-bottom: -3px;
    height: 45px;
  }

  .contentblock {
    //margin-left: 300px;
    //calc( (100vw - 300px));
    //display: block;
    display: block;
    height: 100%;
    width: calc( (100vw - $menu-width));
    margin-left: $menu-width;
    display: inline-block;
    position: fixed !important;
    z-index: 1;
    transition: margin-left 0.5s ease, width 0.5s ease; /* Add transition */
  }

  .contentblockMobile {
    //margin-left: 300px;
    //calc( (100vw - 300px));
    //display: block;
    display: block;
    height: 100%;
    width: calc( (100vw - ($menu-closed-width - $menu-width)));
    margin-left: calc(($menu-closed-width - $menu-width));
    display: inline-block;
    position: fixed !important;
    z-index: 1;
    transition: margin-left 0.5s ease, width 0.5s ease; /* Add transition */
  }

  .mobileContentblock {
    //margin-left: 300px;
    //calc( (100vw - 300px));
    //display: block;
    display: block;
    height: 100%;
    width: 100vw;
    display: inline-block;
    position: fixed !important;
    z-index: 1;
  }

  .dashboardblock {
    width: 100%;
    fill: black;
    display: inline-block;
    height: calc( (100vh - 45px));
    background-color: $back-color;
    overflow: auto;
  }

  .sidebar {
    width: $menu-width;
    height: 100%;
    fill: black;
    border-bottom-color: $sky-theme;
    border-width: medium;
    border-bottom-style: solid;
    background-color: $sky-theme;
    display: block;
    position: fixed!important;
    z-index: 10;
    overflow: auto;
    left: 0; /* Start position for the sidebar */
    transition: left 0.5s ease, width 0.5s ease; /* Add transition */
    text-align: center;
  }

  .sidebarClosed {
    width: 35px;
    height: 100%;
    fill: black;
    border-bottom-color: $sky-theme;
    border-width: medium;
    border-bottom-style: solid;
    background-color: $sky-theme;
    display: block;
    position: fixed!important;
    transition: left 0.5s ease, width 0.5s ease; /* Add transition */
    z-index: 10;
    overflow: auto;
  }

  .sidebarArrowContainer {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .sidebarArrow {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid white; /* Arrow color */
    position: absolute;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust position to center the arrow */
    top: 50%; /* Center vertically */
    transform: translate(-50%, -50%); /* Adjust position to center the arrow */
    cursor: pointer;
  }
  
  .sidebarLogoutIcon {
    /* text-align: center; */
    align-items: center;
    height: 20px;
    /* text-align: center; */
    vertical-align: middle;
    line-height: 50px;
    margin-bottom: 10px;
    margin-right: 8px;
    cursor: pointer;
  }

  .sidebarInfoIcon {
    /* text-align: center; */
    align-items: center;
    height: 20px;
    /* text-align: center; */
    vertical-align: middle;
    line-height: 50px;
    margin-bottom: 10px;
    margin-right: 13px;
    cursor: pointer;
  }

  .sidebarEmailIcon {
    /* text-align: center; */
    align-items: center;
    height: 20px;
    /* text-align: center; */
    vertical-align: middle;
    line-height: 50px;
    margin-bottom: 10px;
    margin-right: 9px;
    cursor: pointer;
  }

  .sidebarlogo {
    text-align: center;
    align-items: center;
    width: 125px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
  }
  .sidebarlogoCircle {
    margin-top: 40px;;
    width: 125px;
    height: 115px;
    border-radius: 15%;
    background-color: white;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    position: relative;
    align-items: center;
  }

  .sidebarState {
    margin-top: 10px;
    margin-left: 10px;
    text-align: center;
    align-items: center;
    width: 150px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    margin-top: 20px;
  }

  .sideHeader {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid white;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
  }

  .sidePortalName{
    text-align: center;
    bottom: 0px;
    margin-top: 75px;
    margin-bottom: 20px;
    font-size: 23px;
    width: 100%;
  }

  .sidePortalLinks{
    position: relative;
    bottom: 0px;
    left: 70px;
    font-size: 23px;
    display: block;
    width: 180px;
  }
  // @media screen and (max-height: 950px) {
  //   .sidePortalLinks{
  //     position: static;
  //     bottom: 0px;
  //     left: 20px;
  //     font-size: 23px;
  //     display: inline-grid;
  //   }
  // }

  .sidePortalDownload {
    left: 20px;
    font-size: 23px;
    display: inline-flex;
    float: left;
  }
  
  .sidePortalContact {
    left: 20px;
    font-size: 23px;
    display: inline-flex;
    float: left;
  }

  .sidePortalInfo {
    left: 26px;
    font-size: 23px;
    display: inline-flex;
    float: left;
  }

  .sidePortalLogout {
    left: 20px;
    margin-bottom: 25px;
    font-size: 23px;
    display: inline-flex;
    float: left;
    width: 84px;
  }

  .sideState {
    height: 50px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    margin-bottom:40px;
    font-size: 13px;
    font-weight: bold;
  }

  .sideAuxButtons {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
    margin-bottom: 40px;
    font-size: 14px;
    font-weight: bold;
    height: 0px;
    cursor: pointer;
  }

  .sideUserInfo {
    display: inline-flex;
    height: 55px;
    margin-bottom: 20px;
    width: $menu-width;
    overflow: hidden;
  }


  .sideButton {
    height: 50px;
    width: 100%;
    border-color: white;
    border-width: medium;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    font-weight: normal;
    background-color: #3c5e87;
    margin: 1px;
    overflow: hidden;
  }

  .sideButtonTop {
    background-color: $sky-theme;
  }

  .sideButtonTop.active  {
  }

  .sideButtonTab {
  }

  .sideButtonTab.active  {
    background-color: #f68326;
  }

  .sideDownload {
    text-align: center;
    position: absolute;
    bottom: 0;
    margin-bottom: 80px;
    font-size: 23px;
    width: 100%;
  }

  .sideDownload:hover {
    cursor: pointer;
    color: #f5f5f5;
  }

  .sideButton.active {
    background-color: #f68326;
    color: #f5f5f5;
  }

  .sideButton.active:hover {
    color: #504c4c;
  }

  .sideButton:hover {
    cursor: pointer;
    color: #f5f5f5;
  }

  .sideMenuIcon {
    height: 20px;
  }

  .tabbar {
    width: 100%;
    fill: black;
    background-color: $back-color;
  }

  .landing {
    overflow: auto;
    //border-bottom-color: $line-theme;
    /* border-bottom-color: black; */
    //border-width: medium;
    //border-bottom-style: solid;
    //display: inline-block;
    width: 100%;
  }

  .dashboardContainer {
    height: calc( (100vh - 45px));
    overflow: auto;
  }

  .loadingSpinnerContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    z-index: 9999;
    top:0
  }
  
  /* Styles for the spinning wheel */
  .loadingSpinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #ffffff; /* Adjust the color as needed */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: fixed;
  }
  
  /* Animation for spinning */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media all and (max-width: 1600px) {
    .tabframe {
        border: 0;
        background-color: $back-color;
        margin-left: 0;
        width: 100%;
        height: calc( (100vh - 45px));
        background-repeat: no-repeat, repeat;
        background-position: center;
        overflow: auto;
      }

      .tabs {
        margin-left: 0
      }

      .icon {
        margin-right: 10px;
      }
 }

 .toolbarMobileLogo {
  float: left;
  margin-top: 11px;
  margin-right: calc((131vw - 1600px) / 2);
  margin-left: 50px;
  cursor: pointer;
  height: 26px;
}