@import "../mixins";
@import "../variables";
@import '~react-pro-sidebar/dist/scss/variables';


.sidebar {
    width: $menu-width;
    height: 100%;
    fill: black;
    border-bottom-color: $sky-theme;
    border-width: medium;
    border-bottom-style: solid;
    background-color: $sky-theme;
    display: block;
    position: fixed!important;
    z-index: 10;
    overflow: auto;
    left: 0; /* Start position for the sidebar */
    transition: left 0.5s ease, width 0.5s ease; /* Add transition */
    text-align: center;
  }

  .sidebarClosed {
    width: 35px;
    height: 100%;
    fill: black;
    border-bottom-color: $sky-theme;
    border-width: medium;
    border-bottom-style: solid;
    background-color: $sky-theme;
    display: block;
    position: fixed!important;
    transition: left 0.5s ease, width 0.5s ease; /* Add transition */
    z-index: 10;
    overflow: auto;
  }

  .sidebarArrowContainer {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .sidebarArrow {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid white; /* Arrow color */
    position: absolute;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust position to center the arrow */
    top: 50%; /* Center vertically */
    transform: translate(-50%, -50%); /* Adjust position to center the arrow */
    cursor: pointer;
  }
  
  .sidebarLogoutIcon {
    /* text-align: center; */
    align-items: center;
    height: 20px;
    /* text-align: center; */
    vertical-align: middle;
    line-height: 50px;
    margin-bottom: 10px;
    margin-right: 8px;
    cursor: pointer;
  }

  .sidebarInfoIcon {
    /* text-align: center; */
    align-items: center;
    height: 20px;
    /* text-align: center; */
    vertical-align: middle;
    line-height: 50px;
    margin-bottom: 10px;
    margin-right: 13px;
    cursor: pointer;
  }

  .sidebarEmailIcon {
    /* text-align: center; */
    align-items: center;
    height: 20px;
    /* text-align: center; */
    vertical-align: middle;
    line-height: 50px;
    margin-bottom: 10px;
    margin-right: 9px;
    cursor: pointer;
  }

  .sidebarlogo {
    text-align: center;
    align-items: center;
    width: 125px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
  }
  .sidebarlogoCircle {
    margin-top: 40px;;
    width: 125px;
    height: 115px;
    border-radius: 15%;
    background-color: white;
    overflow: hidden;
    box-shadow: 0 4px 8px rgb(0 0 0 / 67%);
    display: inline-flex;
    position: relative;
    align-items: center;
  }

  .sidebarState {
    margin-top: 10px;
    margin-left: 10px;
    text-align: center;
    align-items: center;
    width: 150px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    margin-top: 20px;
  }

  .sideHeader {
    width: 100%;
    border-bottom: 1px solid white;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
  }

  .sidePortalName{
    text-align: center;
    bottom: 50px;
    margin-top: 35px;
    margin-bottom: 35px;
    font-size: 23px;
    width: 100%;
  }

  .sidePortalLinks{
    position: relative;
    bottom: 0px;
    left: 70px;
    font-size: 23px;
    display: block;
    width: 180px;
  }
  // @media screen and (max-height: 950px) {
  //   .sidePortalLinks{
  //     position: static;
  //     bottom: 0px;
  //     left: 20px;
  //     font-size: 23px;
  //     display: inline-grid;
  //   }
  // }

  .sidePortalDownload {
    left: 20px;
    font-size: 23px;
    display: inline-flex;
    float: left;
  }
  
  .sidePortalContact {
    left: 20px;
    font-size: 23px;
    display: inline-flex;
    float: left;
  }

  .sidePortalInfo {
    left: 26px;
    font-size: 23px;
    display: inline-flex;
    float: left;
  }

  .sidePortalLogout {
    left: 20px;
    margin-bottom: 25px;
    font-size: 23px;
    display: inline-flex;
    float: left;
    width: 84px;
  }

  .sideState {
    height: 50px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    margin-bottom:40px;
    font-size: 13px;
    font-weight: bold;
    color: black;
  }

  .sideAuxButtons {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
    margin-bottom: 40px;
    font-size: 14px;
    font-weight: bold;
    height: 0px;
    cursor: pointer;
    color: black
  }

  .sideUserInfo {
    display: inline-flex;
    height: 55px;
    margin-bottom: 20px;
    width: $menu-width;
    overflow: hidden;
  }


  .sideButton {
    height: 50px;
    width: 100%;
    border-color: white;
    border-width: medium;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    font-weight: normal;
    background-color: #3c5e87;
    margin: 1px;
    overflow: hidden;
  }

  .sideButtonTop {
    background-color: $sky-theme;
  }

  .sideButtonTop.active  {
  }

  .sideButtonTab {
  }

  .sideButtonTab.active  {
    background-color: #f68326;
  }

  .sideDownload {
    text-align: center;
    position: absolute;
    bottom: 0;
    margin-bottom: 80px;
    font-size: 23px;
    width: 100%;
  }

  .sideDownload:hover {
    cursor: pointer;
    color: #f5f5f5;
  }

  .sideButton.active {
    background-color: #f68326;
    color: #f5f5f5;
  }

  .sideButton.active:hover {
    color: #504c4c;
  }

  .sideButton:hover {
    cursor: pointer;
    color: #f5f5f5;
  }

  .sideMenuIcon {
    height: 20px;
  }

  .userContainer{
    text-align: center;
    color: black;
  }