@import "../mixins";
@import "../variables";

.content {
  @include display-flex();
  @include align-items-center();
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 15vh;
  text-align: center;
}
