$submit-color: #85e4cb;
$main-margin: 10px;
//$line-theme: #eed7b5;
//$line-theme: #f5f5f5;
$line-theme: #000000;
$sky-theme: #4D4D4D;
//$slate-theme: #055160;
$slate-theme: #001b2d;
$stone-theme: #9997A6;
//$back-color: #ebd3b1
$back-color: #ffffff;
$menu-width: 280px;
$menu-closed-width: $menu-width + 35px;
$menu-closed: 35px;